<template>
  <div>
    <v-row class="px-0">
      <v-col v-for="item in status_map" :key="item.status" class="pt-0 pb-2">
        <card-loading v-if="loading" :loading_size="29" card_color="white" text_color="primary--text" loading_color="primary"/>
        <CountStatusesSettings :data="courses_status[`${item.status}`]" :item_icon="item.icon" :item_title="item.title" style="height: 100%" v-if="!loading"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import Api from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'CounterCourseStatus',
  components: {
    CountStatusesSettings: () => import('./counter_by_course_status/CountStatusesSettings.vue'),
  },
  props: {
    current_year: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      courses_status: {},
      status_map:[
       {status: 'active', title: 'Ativos', icon: 'mdi-bookmark-check'},
       {status: 'selecting', title: 'Selecionando', icon: 'mdi-clipboard-clock-outline'},
       {status: 'done_selection', title: 'Seleção pronta', icon: 'mdi-check-circle-outline'},
       {status: 'in_progress', title: 'Em Andamento', icon: 'mdi-progress-clock'},
       {status: 'done', title: 'Finalizados', icon: 'mdi-check-all'},
       {status: 'suspended', title: 'Suspensos', icon: 'mdi-alert-circle-outline'},
      ],
    }
  },
  methods: {
    getData(){
      this.getDashCounter()
    },
    getDashCounter(kind='course_status', filter = { current_year: this.current_year }){
      this.loading = true
      Api.DashCounter.show(kind, filter).then(r=>r.data).then(d=>{
        this.courses_status = {...d}
      }).catch((err) => {
        this.addNotification({type: 'error', msg: `${err}`})
      }).finally(()=>{
        this.loading = false
      })
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },

  mounted () {
    this.getData()
  },
  watch: {
    current_year(){
      this.getData()
    }
  },

}
</script>

<style lang="scss" scoped>

</style>